<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="标题:">
                    <el-input class="s-input" v-model="queryParams.name" placeholder="请输入标题" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="上线" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">添加位置</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column label="id" prop="id" align="center"></el-table-column>
                <el-table-column label="标题" prop="name" align="center"></el-table-column>
                <el-table-column label="key" prop="key" align="center"></el-table-column>
                <el-table-column label="页面" prop="position" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-width="100px" element-loading-text="上传中">
                <el-form-item label="标题" prop="name">
                    <el-input v-model="form.name" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="key" prop="key" v-if="weizhi">
                    <el-input v-model="form.key" placeholder="请输入key"></el-input>
                </el-form-item>
                <el-form-item label="页面" prop="position" v-if="weizhi">
                    <el-select v-model="form.position" placeholder="请选择页面">
                        <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import { deal } from '@/common/main'
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            menuList: ['店铺', '位置管理'],
            loading: false,
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            confirmVisible: false,
            confirmContent: '',
            weizhi: true,
            pageInfo: {},
            addVisible: false,
            title: '添加位置',
            rules: {
                name: [{
                    required: true,
                    message: '标题不能为空',
                    trigger: 'blur'
                }],
                key: [{
                    required: true,
                    message: 'key不能为空',
                    trigger: 'blur'
                }],
                position: [{
                    required: true,
                    message: '页面不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],

            },
            mode: '',
            form: {
            },
            positionList: [],
        }
    },
    created() {
        this.loadData();

    },
    methods: {
        // 获取页面
        getconf() {
            request.get('/common/conf', { position: '', page: 1, pagesize: -1 }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.positionList = ret.data.page
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadData() {
            this.loading = true;
            request.get('/common/position/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        add(id, index) {
            this.getconf()
            this.mode = 'add'
            this.weizhi = true
            this.addVisible = true
            this.form = {
                state: 2,
            };
            this.addVisible = true
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑';
            this.weizhi = false;
            this.form = Object.assign({}, row);
            this.addVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                console.log(222, this.form)
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/common/position/add' : '/common/position/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //状态
        state(row, state) {
            this.confirmVisible = true;
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form.id = row.id
            this.form.state = state
        },
        // 确定下线/
        stateData(row, state) {
            request.post('/common/position/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
.span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}
.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>